define("itarp-landing-frontend/controllers/signup/verify", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "itarp-landing-frontend/config/environment", "@ember/runloop", "@ember/service"], function (_exports, _controller, _tracking, _object, _environment, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SignupVerifyController = (_class = class SignupVerifyController extends _controller.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['email', 'resend_verification']);
      _initializerDefineProperty(this, "links", _descriptor, this);
      _initializerDefineProperty(this, "auth", _descriptor2, this);
      _initializerDefineProperty(this, "code", _descriptor3, this);
      _initializerDefineProperty(this, "errorMsg", _descriptor4, this);
      _initializerDefineProperty(this, "isVerifyInProgress", _descriptor5, this);
      _initializerDefineProperty(this, "isResendDisabled", _descriptor6, this);
      _initializerDefineProperty(this, "timeLeft", _descriptor7, this);
      _initializerDefineProperty(this, "countDownTime", _descriptor8, this);
      _initializerDefineProperty(this, "opportunityId", _descriptor9, this);
      _initializerDefineProperty(this, "talentCloudId", _descriptor10, this);
      _initializerDefineProperty(this, "resend_verification", _descriptor11, this);
      _defineProperty(this, "runner", null);
    }
    initCounter() {
      this.code = '';
      this.errorMsg = '';
      this.startTimer();
    }
    get expertUrl() {
      return this.links.withToken('/expert/autofill');
    }
    get expertApplyOpportunityUrl() {
      return this.links.withToken(`/expert/opportunities?opportunityId=${this.opportunityId}&expertApplies=true`);
    }
    get expertTalentCloudUrl() {
      return this.links.withToken(`/expert/talentclouds/${this.talentCloudId}/details?expertApplies=true`);
    }
    updateVerificationCode(event) {
      this.code = event.target.value;
    }
    validateVerificationCode() {
      this.errorMsg = '';
      this.isVerifyInProgress = true;
      const email = this.email || localStorage.getItem('email');
      const data = JSON.stringify({
        email,
        code: this.code
      });
      try {
        fetch(`/api/verify_email`, {
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          return res.json();
        }).then(json => {
          if (json.errors && json.errors.length) {
            this.errorMsg = json.errors[0];
          } else {
            const userType = this.auth.userType || json.user_type || localStorage.getItem('userType');
            const token = this.auth.token;
            this.opportunityId = localStorage.getItem('opportunityId');
            this.talentCloudId = localStorage.getItem('talentCloudId');
            localStorage.removeItem('userType');
            localStorage.removeItem('opportunityId');
            localStorage.removeItem('talentCloudId');
            if (userType) {
              if (userType === 'expert') {
                if (!token) {
                  return this.transitionToRoute('/signin');
                } else {
                  if (this.talentCloudId) {
                    return window.location.assign(this.expertTalentCloudUrl);
                  } else if (this.opportunityId) {
                    return window.location.assign(this.expertApplyOpportunityUrl);
                  } else {
                    return window.location.assign(this.expertUrl);
                  }
                }
              } else if (userType === 'guest_user') {
                this.transitionToRoute('landing-page-guest');
              } else {
                this.transitionToRoute('landing-page');
              }
            } else {
              this.transitionToRoute('landing-page');
            }
          }
        }).catch(error => {
          this.errorMsg = error.toString().split(':')[1];
          this.isVerifyInProgress = false;
        }).finally(() => {
          this.isVerifyInProgress = false;
        });
      } catch (error) {
        this.errorMsg = error.toString().split(':')[1];
        this.isVerifyInProgress = false;
      }
    }
    sendVerification() {
      this.startTimer();
      const data = JSON.stringify({
        email: localStorage.getItem('email')
      });
      try {
        fetch(`/api/send_verification_code`, {
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error('Error in email verification');
          }
        }).then(json => {
          this.errorMsg = null;
        }).catch(error => {
          this.errorMsg = error.toString().split(':')[1];
        });
      } catch (error) {
        this.errorMsg = error.toString().split(':')[1];
      }
    }
    startTimer() {
      (0, _runloop.next)(this, function () {
        this.isResendDisabled = true;
        const timeAfterOneMinute = new Date();
        timeAfterOneMinute.setMinutes(timeAfterOneMinute.getMinutes() + 1);
        this.countDownTime = timeAfterOneMinute.getTime();
        this.runner = this.tick();
      });
    }
    stopTimer() {
      this.isResendDisabled = false;
      (0, _runloop.cancel)(this.runner);
    }
    tick() {
      return (0, _runloop.later)(this, function () {
        const now = new Date().getTime();
        const distance = this.countDownTime - now;
        if (distance < 0) {
          this.stopTimer();
        }
        let minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
        let seconds = Math.floor(distance % (1000 * 60) / 1000);
        minutes = minutes < 0 ? 0 : minutes;
        seconds = seconds < 0 ? 0 : seconds;
        const twoDigitsMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const twoDigitsSeconds = seconds < 10 ? `0${seconds}` : seconds;
        let timeLeft = `${twoDigitsMinutes}:${twoDigitsSeconds}`;
        this.timeLeft = timeLeft;
        this.runner = this.tick();
      }, 1000);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "links", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "auth", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "code", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorMsg", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isVerifyInProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isResendDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "timeLeft", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "countDownTime", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "opportunityId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "talentCloudId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "resend_verification", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateVerificationCode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateVerificationCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateVerificationCode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateVerificationCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendVerification", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendVerification"), _class.prototype)), _class);
  _exports.default = SignupVerifyController;
});