define("itarp-landing-frontend/routes/signup/verify", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class SignupVerifyRoute extends _route.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", {
        resend_verification: {
          refreshModel: false
        }
      });
    }
    model(params) {
      return params;
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      if (model.resend_verification) {
        controller.sendVerification();
      }
      controller.initCounter();
    }
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('resent_verification', null);
      }
    }
  }
  _exports.default = SignupVerifyRoute;
});